<template>
  <Toast />
  <div
    class="
      surface-0
      flex
      align-items-center
      justify-content-center
      min-h-screen min-w-screen
      overflow-hidden
    "
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 60%">
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="text-center mb-5">
            <Image src="/app/front/images/logo.png" alt="Image"/>
            <hr/>
            <b class="text-600 text-1xl font-medium mb-3">Gerenciador para IPTU</b>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2"
              >Email</label
            >
            <InputText
              id="email1"
              v-model="email"
              type="email"
              class="w-full mb-3"
              placeholder="Email"
              style="padding: 1rem"
            />

            <label
              for="password1"
              class="block text-900 font-medium text-xl mb-2"
              >Senha</label
            >
            <Password
              id="password1"
              v-model="password"
              placeholder="Senha"
              class="w-full mb-3"
              inputClass="w-full"
              inputStyle="padding:1rem"
            ></Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <a
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: var(--primary-color)"
                >Esqueceu a Senha?</a
              >
            </div>
            <Button
              label="Acessar"
              class="w-full p-3 text-xl"
              @click="login()"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      checked: false,
    };
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  mounted() {},
  methods: {
    dash() {},

    getUser() {
      
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      this.axios
        .get("/user",config)
        .then((response) => {
          console.log( response.data.user);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    login() {
      let self = this;
      let data = {
        password: this.password,
        email: this.email,
      };
      this.axios
        .post("/login", data)
        .then((response) => {
          self.$toast.add({
            severity: "success",
            summary: "Sucesso!!",
            detail: "Bem vindo!!",
            life: 3000,
          });
          console.log(response);
          localStorage.setItem("token", response.data.token);
          this.getUser();
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>